// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-success-js": () => import("./../../../src/pages/auth-success.js" /* webpackChunkName: "component---src-pages-auth-success-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-caviste-js": () => import("./../../../src/pages/caviste.js" /* webpackChunkName: "component---src-pages-caviste-js" */),
  "component---src-pages-click-and-collect-js": () => import("./../../../src/pages/click-and-collect.js" /* webpackChunkName: "component---src-pages-click-and-collect-js" */),
  "component---src-pages-conditions-generales-dutilisation-js": () => import("./../../../src/pages/conditions-generales-dutilisation.js" /* webpackChunkName: "component---src-pages-conditions-generales-dutilisation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demander-une-demo-js": () => import("./../../../src/pages/demander-une-demo.js" /* webpackChunkName: "component---src-pages-demander-une-demo-js" */),
  "component---src-pages-devis-js": () => import("./../../../src/pages/devis.js" /* webpackChunkName: "component---src-pages-devis-js" */),
  "component---src-pages-e-commerce-js": () => import("./../../../src/pages/e-commerce.js" /* webpackChunkName: "component---src-pages-e-commerce-js" */),
  "component---src-pages-epicerie-js": () => import("./../../../src/pages/epicerie.js" /* webpackChunkName: "component---src-pages-epicerie-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-fromagerie-js": () => import("./../../../src/pages/fromagerie.js" /* webpackChunkName: "component---src-pages-fromagerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-fidelite-js": () => import("./../../../src/pages/la-fidelite.js" /* webpackChunkName: "component---src-pages-la-fidelite-js" */),
  "component---src-pages-le-point-de-vente-js": () => import("./../../../src/pages/le-point-de-vente.js" /* webpackChunkName: "component---src-pages-le-point-de-vente-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-merci-js": () => import("./../../../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-pages-merci-telechargement-js": () => import("./../../../src/pages/merci-telechargement.js" /* webpackChunkName: "component---src-pages-merci-telechargement-js" */),
  "component---src-pages-multiboutique-js": () => import("./../../../src/pages/multiboutique.js" /* webpackChunkName: "component---src-pages-multiboutique-js" */),
  "component---src-pages-pay-account-success-js": () => import("./../../../src/pages/pay-account-success.js" /* webpackChunkName: "component---src-pages-pay-account-success-js" */),
  "component---src-pages-pay-transaction-request-js": () => import("./../../../src/pages/pay-transaction-request.js" /* webpackChunkName: "component---src-pages-pay-transaction-request-js" */),
  "component---src-pages-pay-transaction-success-js": () => import("./../../../src/pages/pay-transaction-success.js" /* webpackChunkName: "component---src-pages-pay-transaction-success-js" */),
  "component---src-pages-tableau-de-bord-js": () => import("./../../../src/pages/tableau-de-bord.js" /* webpackChunkName: "component---src-pages-tableau-de-bord-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/Article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/Blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-form-index-js": () => import("./../../../src/templates/Form/index.js" /* webpackChunkName: "component---src-templates-form-index-js" */)
}

